<template lang="pug">
  v-dialog(v-model="value" persistent :width="width")
    v-card()
      title-bar(dark :loading="loading") {{ dialogTitle }}

      v-card-text(v-if="dialogBody !== ''") {{ dialogBody }}

      v-card-text(data-cy="dialog-body" v-if="$slots.body")
        slot(name="body")

      v-card-actions
        v-spacer
        v-btn(v-if="!hideCancelButton" flat @click="$emit('click:cancel')") {{ cancelButtonText }}
        v-btn.primary(@click="$emit('click:ok')" data-cy="submit-button") {{ okButtonText }}
</template>
<script>
import TitleBar from './LDTitleBar';

export default {
  components: {
    TitleBar,
  },
  name: 'LdDialog',
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    /**
     * The Title bar we want to display
     */
    dialogTitle: String,
    /**
     * The body we want to display
     */
    dialogBody: {
      type: String,
      default: '',
    },
    /**
     * The text we want to show in the ok button
     */
    okButtonText: {
      type: String,
      default: 'Ok',
    },
    /**
     * The text we want to show in the cancel buton
     */
    cancelButtonText: {
      type: String,
      default: 'Cancel',
    },
    /**
     * Shows whether the dialog is open or not
     */
    value: {
      type: Boolean,
    },
    hideCancelButton: {
      type: Boolean,
      default: false,
    },
    width: {
      type: String,
      default: '480px',
    },
  },
};
</script>
