<template lang="pug">
  v-card-title.c-title(:class="cssClass", :style="cssStyle")
    span.title(v-if="showTitle" data-cy="title")
      slot
    slot(v-if="showTitle" name="sub-title")
    span(:class="{ menu: isLowerMenuOpacity }")
      slot(name="menu")
    .inline-badge(v-if="hasBadge" data-cy="inline-badge") {{badge}}
    // need to conditionally increase opacity of this slot so
    // slide drawers will appear over data table
    span(class="{ menu: isLowerMenuOpacity }")
      slot(name="actions")
    v-spacer
    span
      slot(name="extras")
    .progress(v-show="loading")
      v-progress-linear(:indeterminate="true")
</template>

<script>
export default {
  name: 'LdTitleBar',
  props: {
    // determine if menu items have reduced opacity
    isLowerMenuOpacity: {
      type: Boolean,
      default: true,
    },
    title: {
      type: String,
    },
    dark: {
      type: Boolean,
    },
    color: {
      type: String,
    },
    loading: {
      type: Boolean,
    },
    badge: {
      default: null,
      type: [String, Number],
    },
    // for making sure title bar header is aligned properly
    paddingLeft: {
      type: String,
      default: '58px',
    },
    showTitle: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    cssClass() {
      if (this.dark) {
        return `dark ${this.backgroundColor} white--text`;
      }
    },
    backgroundColor() {
      if (this.dark) {
        return this.color || this.$vuetify.theme.primary;
      }
      return 'transparent';
    },
    cssColor() {
      if (this.dark) {
        return 'rgba(255,255,255,0.5)';
      }
      return this.color || this.$vuetify.theme.primary;
    },
    cssStyle() {
      return `
        --tag-color:${this.cssColor};
        background-color:${this.backgroundColor};
        padding: 6px 0 6px ${this.paddingLeft};
      `;
    },
    hasBadge() {
      // numeric badge with value of 0 still must be shown
      return this.badge || String(this.badge) === '0';
    },
  },
};
</script>
<style lang="scss" scoped>
.c-title {
  font-size: 20px;
  font-weight: 500;
  line-height: 26px;
  min-height: 60px;
  width: 100%;
  .title {
    text-transform: uppercase;
    padding-right: 12px;
    &:before {
      width: 16px;
      height: 16px;
      position: absolute;
      content: '';
      margin: 0 -30px;
      border-radius: 2px;
      background: var(--tag-color);
    }
  }
  .sub-title {
    font-size: 16px;
    text-transform: none;
    opacity: 0.6;
  }
  .title-hyphen {
    width: 8px;
    background: var(--v-text-lighten3);
    opacity: 0.5;
    height: 2px;
    margin: 0 12px 0 0;
  }
  &.dark {
    .title-hyphen {
      background: white;
    }
  }
  .menu {
    text-transform: uppercase;
    opacity: 0.5;
    .v-menu {
      vertical-align: inherit;
    }
  }
  .inline-badge {
    margin: 0 5px;
    color: var(--v-text-lighten3);
    border-radius: 4px;
    padding: 0 6px;
    text-align: center;
    font-size: 16px;
  }
  &.dark {
    .inline-badge {
      color: white;
      background-color: var(--tag-color);
    }
  }
}
</style>
